import React from 'react';
import "./Home.css";

const Home = (props) => {
    return (
        <div className="home-wrapper">
            <div className="text-wrapper">
                <div className="intro-text">
                    <h1 className="hi-manu">Hi,<br/>I'm<mark className="blue"> Manu!</mark></h1>
                    <h2 className="desc-manu">I love coding, building,<br/> and designing</h2>
                    <div className="swipe-info">
                        <img src = {process.env.PUBLIC_URL + "/images/swipe.gif"} alt="Swipe Instruction" width="50px" height="50px"></img>
                        <p>Swipe</p>
                    </div>
                </div>
            </div>
            <div className="graphic-wrapper">
                <div className="blue-square">
                    <div className="blue-inner">
                        <div className="keyboard-keys">
                            <img className="key up-key" src = {process.env.PUBLIC_URL + "/images/up-arrow-button-icon.svg"} alt="up"></img>
                            <div className="bottom-row">
                                <img className="key left-key" src = {process.env.PUBLIC_URL + "/images/left-arrow-button-icon.svg"} alt="left"></img>
                                <img className="key down-key" src = {process.env.PUBLIC_URL + "/images/down-arrow-button-icon.svg"} alt="down"></img>
                                <img className="key right-key" src = {process.env.PUBLIC_URL + "/images/right-arrow-button-icon.svg"} alt="right"></img>
                            </div>
                        </div>
                        <p className="keyboard-text">Use the arrow keys to navigate</p>
                    </div> 
                </div>
            </div>
        </div>
    );
};

export default Home;