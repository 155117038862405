import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./ContactMe.css";

const DEFAULT = "DEFAULT";
const SUBMITTING = "SUBMITTING";
const SUCCESS = "SUCCESS";
const ERROR = "ERROR";

const ContactMe = (props) => {
    const form = useRef();

    const [ status, setStatus ] = useState(DEFAULT);

    const sendEmail = (e) => {
        e.preventDefault();

        setStatus(SUBMITTING);
        emailjs.sendForm('contact_service', 'contact_form', form.current, '_JMVHez3vTvYR4-c9')
          .then((result) => {
            setStatus(SUCCESS);
              console.log(result.text);
          }, (error) => {
            setStatus(ERROR);
              console.log(error.text);
          });

         setTimeout(function () {
            setStatus(DEFAULT);
        }, 5000);
    };

    const generateButtonText = () => {
        switch (status) {
            case DEFAULT:
                return "Submit";
            case SUBMITTING:
                return (<><p>Contacting... </p><div class="loader"></div></>);
            case SUCCESS:
                return "Message sent!";
            case ERROR:
                return "Message did not send!";
            default:
                return "Submit";
        }
    }

    const generateStyling = () => {
        switch (status) {
            case DEFAULT:
                return {};
            case SUBMITTING:
                return {backgroundColor: "#4C8BF5", color: "white"};
            case SUCCESS:
                return {backgroundColor: "#3CB043", borderColor: "#3CB043", color: "white"};
            case ERROR:
                return {backgroundColor: "#DC143C", borderColor: "#DC143C", color: "white"};
            default:
                return "Submit";
        }
    }

    return (
        <div className="contactme-wrapper">
            <h2 className="contactme-title">Contact Me</h2>
            <div className="row-wrapper">
                <form ref={form} className="form-wrapper" onSubmit={sendEmail}>
                    <label className="contact-label" htmlFor="user_name">Name</label>
                    <input className="contact-field" type="text" placeholder="John Doe" name="user_name" />
                    <label className="contact-label" htmlFor="user_email">Email</label>
                    <input className="contact-field" type="email" placeholder="john@gmail.com" name="user_email" />
                    <label className="contact-label" htmlFor="message">Message</label>
                    <textarea  className="contact-message" placeholder="I want to contact you because..." name="message"></textarea>
                    <button disabled={status === SUBMITTING} className="contact-button" style={generateStyling()}>
                        {generateButtonText()}
                    </button>
                </form>

                <div className="social-wrapper">
                    <h2 className="social-title">Social</h2>
                    <div className="social-row">
                        <a href="https://github.com/mgupta8143" target="_blank" rel="noreferrer" ><img className="social-logo" src = {process.env.PUBLIC_URL + "/images/github-social.svg"} alt="Github logo"></img></a>
                        <a className="social-text" href="https://github.com/mgupta8143" target="_blank" rel="noreferrer">Github</a>
                    </div>
                    <div className="social-row">
                        <a  href="https://www.linkedin.com/in/manu-gupta-41599b1a6/" target="_blank" rel="noreferrer"><img className="social-logo" src = {process.env.PUBLIC_URL + "/images/linkedin-social.svg"} alt="Linkedin logo"></img></a>
                        <a className="social-text" href="https://www.linkedin.com/in/manu-gupta-41599b1a6/" target="_blank" rel="noreferrer">LinkedIn</a>
                    </div>
                    <div className="social-row">
                        <a  href="mailto:mgupta8143@gmail.com" target="_blank" rel="noreferrer"><img className="social-logo" src = {process.env.PUBLIC_URL + "/images/mail.svg"} alt="Email icon"></img></a>
                        <a className="social-text" href="mailto:mgupta8143@gmail.com" target="_blank" rel="noreferrer">Email</a>
                    </div>
                </div>
            </div>
        </div>
    )

    //                 <img className="github-logo" src = {process.env.PUBLIC_URL + "/images/Github.png"} alt="Github logo"/>

};

export default ContactMe;