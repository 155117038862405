import './ExperienceList.css';
import React from 'react';
import ExperienceComponent from './ExperienceComponent';

const ExperienceList = ({experienceData}) => {
    return (
        <div className="ex-list-wrap">
            {
                experienceData.map((experience, index) => {
                    let {company, role, img, date, desc} = experience;
                    return <ExperienceComponent
                                company={company}
                                role={role}
                                img={img}
                                date={date}
                                desc={desc}
                                key={index}
                            />
                })
            }
        </div>
    );
}

export default ExperienceList;