import React from 'react';
import "./Experience.css";
import "./ExperienceList/ExperienceList";
import ExperienceList from './ExperienceList/ExperienceList';
import { IoMdDownload } from 'react-icons/io';
import SkillSlider from './SkillSlider/SkillSlider';

const Experience = (props) => {
    const experienceData = [
        {
           company: "Palantir Technologies",
           role: "Software Engineer Intern",
           img: "/images/companyLogos/palantir_logo.jpeg",
           date: "May 2023 - August 2023" ,
           desc: "• Contributed to the development and implementation of a low-latency streaming service for ingesting and querying geotemporal data using Kafka, Flink, and Elasticsearch\n \
           • Implemented parallelized websocket hotpath to synthesize and stream geospatial data from various sources, assisting several important contracts\n \
           • Architected service used company-wide to asynchronously bulk delete geotemporal data, supporting essential workflows",
        },
        {
            company: "Bits of Good",
            role: "Associate Director of Engineering",
            img: "/images/companyLogos/bitsofgood_logo.webp",
            date: "January 2022 - Present",
            desc: "Associate Director of Engineering\n • Assisting in promoting a healthy engineering culture and technical vision for Bits of Good \n\
             • Managing recruitment, overseeing growth of peers, and cultivating projects in the engineering department \
             \n\nEngineering Manager\n • Oversaw the maintenance and improvement of 8 non-profit products\n \
             • Led team of several developers and determined important engineering problems to solve \
             \n\nEngineering Manager\n • Worked with non-profit GeorgiaCORE to create visual chatbot tool\n• Project successfully completed with great delight to non-profit  \n\nEngineering Manager\n• Worked with non-profit Fair Districts GA to build internal automation tool to help fight gerrymandering\n • Managed sprint cycles and CI/CD for a team of 5 developers who are using Next.js, Prisma, and PostgreSQL \n  • Project successfully completed with great delight to non-profit",
        },
        {
            company: "Georgia Institute of Technology",
            role: "Undergraduate Teaching Assistant",
            img: "/images/companyLogos/gt.webp",
            date: "August 2022 - December 2022",
            desc: "• Helping students in CS 2340 (Objects and Design) achieve their maximum potential by holding office hours and meeting with them virtually to discuss design patterns, Agile principles, version control, etc. \n • Answering questions on Piazza for students related to object-oriented design/analysis and software engineering patterns\n • Updating class website resource called OBDES to help students have a centralized source of information to refer to",
        },
        {
            company: "Cox Enterprises",
            role: "Software Engineer Intern",
            img: "/images/companyLogos/cox_logo.webp",
            date: "May 2022 - August 2022",
            desc: "• Worked on Simulcast bidding engine ($60 billion in yearly vehicle transactions) under Cox’s implementation of the scaled agile framework, CAFe \n • Improved several core applications within the engine dealing with bidding displays, buyer disclosures, proxy bids, and dealer registrations using Sprint Boot, React, Struts, JDBC, Vagrant, IBM DB2, PCML, Maven, Jenkins, etc.\n • Reduced buyer disclosure upload times by 52% by leading initiative to migrate 10+ year old JAR to web application\n • Leveraged AWS resources (SQS, EC2, ELB, Cloud Formation, etc.) using Terraform/AWS CDK to improve Simulcast architecture resiliency and organization (supports ∼2M users peak)",
        },
        {
            company: "Software Engineer Intern",
            role: "Fiveable",
            img: "/images/companyLogos/fiveable_logo.webp",
            date: "Jul 2021 - Aug 2021",
            desc: "• Developed live-time chat deletion feature within studying platform for 50,000+ clients utilizing Next.js, Express, Socket.io, and MongoDB. \n• Optimized run-time efficiency of fixed window and leaky bucket rate limiting by 25% for socket events. \n• Collaborated with lead software engineer to help refactor codebase and improve Spotify integration performance by 75%.\n• Attended workshops to enhance skills in Test Driven Development using tools like Jest, etc."
        },
        {
            company: "Pride Places",
            role: "Full Stack Developer Intern",
            img: "/images/companyLogos/prideplaces_logo.webp",
            date: "May 2021 - Aug 2021",
            desc: "• Refined and scaled Git Ops process for team of 10+ developers using name-branching, cutting development time in half. \n• Worked with CEO, CTO, and product design team to designate issues on Jira and fine-tune product on Figma.\n• Helped build responsive web application that contains dynamic user content using Next.js framework and Directus CMS.\n• Built React Components (FAQ, Social Media Embeds) for business listings and fixed backend bugs with vanishing data."
        },
        {
            company: "OpenMRS",
            role: "Open Source Developer",
            img: "/images/companyLogos/openmrs_logo.webp",
            date: "Dec 2019 - Jan 2020",
            desc: "• Merged pull requests to improve maintainability and lower redundancy of code in OpenMRS allergy API.\n • Worked on JIRA tickets to maintain documentation of OpenMRS modules."
        },
    ];

    return (
        <div className="experience-wrapper">
            <div className="resume-wrapper">
                <img
                  className="resume"
                  src="/images/Manu_Gupta_Resume.png"
                  alt="Manu's Resume"
                />
                <a 
                 className='download-resume'
                 href="/pdf/Manu_Gupta_Resume.pdf"
                 target="_blank"
                >
                    Download
                    <IoMdDownload className="download-symbol"/>
                </a>
            </div>
            <div className="skills-wrapper">
                <h2 className="exp-title">
                    Experience & Skills
                    <a 
                    className='download-resume mobile'
                    href="/pdf/Manu_Gupta_Resume.pdf"
                    target="_blank"
                    >
                        Resume
                    </a>
                </h2>
                <div className="descriptions-wrapper">
                    <ExperienceList experienceData={experienceData}></ExperienceList>
                </div>
                <SkillSlider
                  className="skills" 
                  title="Languages"
                  items={["C++", "C", "Java", "Python", "JavaScript", "TypeScript", "C#", "HTML/CSS", "SQL", "ARM", "x86"]}
                />
                <SkillSlider
                  className="skills" 
                  title="Frameworks/Tools"
                  items={["Node.js", "React.js", "Next.js", "Pytorch", "Numpy", "Matplotlib", "Scikit-learn", "Spring/Spring Boot", "Elasticsearch", "Kafka", "Flink", "Witchcraft", "Conjure", "React Native", "Linux", "Git", "Unity", "JIRA", "PostgreSQL", "MongoDB", "Redis", "MySQL", "AWS EC2", "AWS Lambda", "AWS Elastic Beanstalk", "AWS Cloudfront", "Rally", "Ansible", "Terraform", "Vagrant", "Java Struts 2", "JDBC", "IBM DB2","PCML", "Maven", "Jenkins", "GitHub Actions"]}
                />
              
            </div>
        </div>
    );
};

export default Experience;