import React from 'react';
import './ExperienceComponent.css'


const ExperienceComponent = ({company, role, img, desc, date}) => {
    return (
        <div className='exp-comp-wrapper'>
            <div className='img-bar-wrapper'>
                <img 
                  className="company-logo-img"
                  alt={company + "logo"}
                  src={`${process.env.PUBLIC_URL}${img}`}>
                </img>      
                <div 
                  className="dash-line"
                >
                </div>
            </div>
            <div className='info-wrapper'>
                <h3 className="info-role">{role}</h3>
                <h4 className="info-company">{company}</h4>
                <h5 className="info-date">{date}</h5>
                <p className="info-desc">{desc}</p>
            </div>
        </div>
    );
};

export default ExperienceComponent;