import './SkillSlider.css';
import React from 'react';

const SkillSlider = ({title, items}) => {
    return (
        <div className="skill-wrap">
            <h4 className="skill-title">{title}</h4>
            <div className="skill-item-wrap">
            {
                items.map((item, idx) => {
                    return (
                    <div className="skill-item" key={idx}>
                        {item}
                    </div>
                    );
                })
            }
            </div>
        </div>
    );
}

export default SkillSlider;