import React, { useState, useEffect, Suspense} from 'react';
import "./Slider.css";
import Home from "../Home/Home";
import Experience from "../Experience/Experience";
import Projects from "../Projects/Projects";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import ContactMe from '../ContactMe/ContactMe';

const Slider = (props) => {
    const sliderArr = [
        {
            desc: "About Me",
            data: <Home />
        },
        {
            desc: "Experience & Skills",
            data: <Experience />
        },
        {
            desc: "Projects",
            data: <Projects />
        },
        {
            desc: "Contact Me",
            data: <ContactMe/>
        },
    ];

    const initializePreload = (sliderArr) => {
        return sliderArr.map(slideInfo => {
            return {
                ...slideInfo,
                data: <div></div>,
            }
        });
    }

    var [x, setX] = useState(0);
    const [preload, setPreload] = useState(initializePreload(sliderArr));

    console.log(preload);


    const goLeft = () => {
        setX(x => {
            if (x === 0) return -100*(sliderArr.length - 1);
            return x + 100;
        })
    };

    const goRight = () => {
        setX(x => {
            if ((x === -100*(sliderArr.length-1))) return 0;
            return x - 100;
        });
    };

    const indicatorArr = [];
    for(let i = 0; i < sliderArr.length; ++i) {
        const handleTickClick = () => {
            setX(i * -100);
        };
        const tick = (
            <div
             className="indicator" 
             id={(x/-100) === i ? "blue" : ""}
             onClick={handleTickClick}
            >
            </div>
        );
        indicatorArr.push(tick);
    }

    useEffect(() => {
        const handleArrowClick = (e) => {
            if (e.repeat) return;
            const LEFT = 37;
            const RIGHT = 39;

            if (e.keyCode === LEFT) {
                goLeft();
            } else if (e.keyCode === RIGHT) {
                goRight();
            }
        }

        let touchStart = 0;
        let touchEnd = 0;

        const handleTouchStart = e => {
            touchStart = e.changedTouches[0].screenX;
        }

        const handleTouchEnd = e => {
            let excludedZones = new Set(["skill-item", "skill-wrap", "skill-item-wrap"]);

            if(excludedZones.has(e.target.className)) {
                return;
            }
            console.log(e.target.className);
            touchEnd = e.changedTouches[0].screenX;

            let dist = touchEnd - touchStart;
            const THRESHOLD = 50;

            if (dist >= THRESHOLD) {
                goLeft();
            } else if (dist <= -1 * THRESHOLD) {
                goRight();
            }
        }

        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchend', handleTouchEnd);
        document.addEventListener('keydown', handleArrowClick); 

        return () => {
            document.removeEventListener('keydown', handleArrowClick);
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchend', handleTouchEnd);
        }
    }, []);
   
    return (
        <div className="slider" data-theme="dar">
            {
                sliderArr.map((item, index) => {
                    return (
                    <div key={index} className="slide" style={{transform: `translateX(${x}%)`}}>
                        {item.data}
                    </div>
                    )
                })
            }
            <button className={(x === 0) ? "hide": "show"} id="goLeft" onClick={goLeft}>
                <IoIosArrowBack className='arrow-icon'/>
            </button>
            <div className="left-preview">
                <p className="bold">
                    Previous: 
                </p>
                <p className="preview-text">
                    {sliderArr[x === 0 ? sliderArr.length - 1 : (x/-100) - 1].desc}
                </p>
            </div>
            <button id="goRight" onClick={goRight}>
                <IoIosArrowForward className='arrow-icon'/>
            </button>
            <div className="right-preview">
                <p className="bold">
                    Next: 
                </p>
                <p className="preview-text">
                    {sliderArr[x === -100*(sliderArr.length-1) ? 0 : (x/-100) + 1].desc}
                </p>
            </div>
            <div className="slide-indicator">{indicatorArr}</div>
        </div>
    )
};

export default Slider;