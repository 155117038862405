import React from 'react';
import "./Projects.css";

const Projects = (props) => {

    const projList = [
        {
            src: process.env.PUBLIC_URL + "/images/xv6-project-image.png",
            alt: "xv6-logo",
            title: "xv6-upgraded",
            desc: "Collaborated with partner to add CoW paging, kernel-level threading with accompany user-space library, and user-level permissioning to xv6 operating system. (Note: Code is kept private)",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png",
            disableLinks: true,
        },
        {
            src: process.env.PUBLIC_URL + "/images/clip.webp",
            alt: "CLIPSearch logo",
            title: "CLIPSearch",
            desc: "Utilized OpenAI's new CLIP model and trained it on Unsplash's dataset of 25,000 images to create an API that takes input text and outputs related image results. Deployed on a memory-optimized EC2 instance.",
            codeLink: "https://github.com/mgupta8143/CLIPSearch",
            demoLink: "https://www.youtube.com/watch?v=i-F6k-nZ4nw",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png", 
            disableLinks: false,
        },
        {
            src: process.env.PUBLIC_URL + "/images/handwritten.webp",
            alt: "Handwritten Recognition",
            title: "Handwriting Recognition",
            desc: "Worked with a team of 5 students to architect several different models (Mini-batch K-Means, CNN, CNN + Max Pooling) to parse handwritten text using PyTorch and NumPy. Achieves 98.75% accuracy on training set.",
            codeLink: "https://github.com/KristenEBrown/Handwriting-Recognition",
            demoLink: "https://kristenebrown.github.io/Handwriting-Recognition/",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png", 
            disableLinks: false,
        },
        {
            src: process.env.PUBLIC_URL + "/images/launchcad.webp",
            alt: "LaunchCAD Logo",
            title: "LaunchCAD",
            desc: "As a part of HackGT8, my roommates and I created a mobile app (in 36 hours) that launches isometric drawings into 3D CAD models using computer vision. Won top 8 of 200 teams and met billionaire Chris Klaus.",
            codeLink: "https://github.com/srikantv03/iso-to-cad",
            demoLink: "https://devpost.com/software/launchcad",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png",
            disableLinks: false,
        },
        {
            src: process.env.PUBLIC_URL + "/images/fair-districts.webp",
            alt: "Fair Districts GA logo",
            title: "Fair Districts GA (Hack4Impact)",
            desc: "This workflow application was created for a non-profit to enable them to automate tedious tasks needed to fight gerrymandering in Georgia. Application and algorithms were developed using Prisma, Next, and PostgreSQL.",
            codeLink: "https://github.com/GTBitsOfGood/fair-districts-ga",
            demoLink: "https://fair-districts.vercel.app/",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png",
            disableLinks: false,
        },
        {
            src: process.env.PUBLIC_URL + "/images/tower-defense.webp",
            alt: "Tower Defense Game",
            title: "Tower Defense Game",
            desc: "Worked with other students to develop a simple tower defense game using Android Studio using common design patterns like Factory, Singleton, and Decorator patterns.",
            codeLink: "https://www.youtube.com/watch?v=UOTmNs5A3EE",
            demoLink: "https://www.youtube.com/watch?v=UOTmNs5A3EE",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png",
            disableLinks: false, 
        },
        {
            src: process.env.PUBLIC_URL + "/images/naruto.webp",
            alt: "Naruto GBA Game",
            title: "Naruto on Gameboy Advance",
            desc: "This ROM game was created using mGBA emulator and C. This project used specific hardware in GBA (DMA, GBA registers, etc.) to prevent tearing and uses many fundamental C programming concepts (FSM, Heap, etc.)",
            codeLink: "https://github.com/mgupta8143/CS-2110/tree/main/hw08",
            demoLink: "https://youtu.be/KfQkoWm84cM",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png",
            disableLinks: false,
        },
        {
            src: process.env.PUBLIC_URL + "/images/CubeTube.webp",
            alt: "Cube Tube image",
            title: "Cube Tube",
            desc: "This game is a 3D-Endless Platformer built in Unity and C#. The project takes advantage of physics manipulations, payment systems, advertising, leaderboard APIs, and coroutines.",
            codeLink: "https://github.com/mgupta8143/cube-tube",
            demoLink: "https://play.google.com/store/apps/details?id=com.cube.tube&hl=en_US&gl=US",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Playstore.jpg",
            demoSize: "1.8vw",
            demoTextMarginLeft: "0vw",
            disableLinks: false,
        }, 
        {
            src: process.env.PUBLIC_URL + "/images/BasketballHead.png",
            alt: "Basketball Head image",
            title: "Basketball Head",
            desc: "This is a live-time NBA chatting application where basketball fans can enjoy games together as scores update. This projects was built using Node, Express, Socket.io, and MongoDB.",
            codeLink: "https://github.com/mgupta8143/bbhead-comm",
            demoLink: "https://bbhead-comm.herokuapp.com/",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png",
            disableLinks: false,

        },
        {
            src: process.env.PUBLIC_URL + "/images/SortingVisualizer.webp",
            alt: "Sorting Visualizer image",
            title: "Sorting Visualizer",
            desc: "This application helps visualize sorting algorithms using color and distance. This application was built using React and Bootstrap.",
            codeLink: "https://github.com/mgupta8143/Sorting-Visualizer",
            demoLink: "https://mgupta8143.github.io/Sorting-Visualizer/",
            demoLogoPath: process.env.PUBLIC_URL + "/images/Internet.png",
            disableLinks: false,
        }

    ];

    return (
        <div className="projects-wrapper">
            <h2 className="proj-title">Projects</h2>
            <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
            <div className="proj-list">
                {
                    projList.map((item, idx) => {
                        return (
                            <div className="proj-wrapper" key={idx}>
                                <img className = "proj-img" src={item.src} alt={item.alt} ></img>
                                <div className="proj-text-wrapper">
                                    <h2 className="proj-box-title">{item.title}</h2>
                                    <h4 className="proj-desc">{item.desc}</h4>
                                    <div className="btn-row">
                                        <a className="code-button" href={item.codeLink} target="_blank" rel="noreferrer">
                                            <img className="github-logo" src = {process.env.PUBLIC_URL + "/images/Github.webp"} alt="Github logo"/>
                                            <p className="code-text">Code</p>
                                        </a>
                                        <a className="demo-button" href={item.demoLink} target="_blank" rel="noreferrer">
                                            <img className="demo-logo" src={item.demoLogoPath} alt="Demo logo" style={{width: item.demoSize, height: item.demoSize}}/>
                                            <p className="demo-text" style={{marginLeft: item.demoTextMarginLeft}}>Demo</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default Projects;